import React from 'react';
import Layout from '../components/layout';

export default () => (
  <Layout>
    <section class="ds section_404 background_cover section_padding_top_130 section_padding_bottom_130">
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-md-offset-3 text-center">
            <div class="inline-block text-center">
              <p class="not_found">
                <span class="highlight">404</span>
                <span class="oops grey">Oops!</span>
              </p>
              <h3 class="text-uppercase">Oops, page not found!</h3>
              <p>
                <a href="/" class="theme_button color1">Back to Homepage</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);
